@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}
body::-moz-scrollbar {
  width: 7px;
  height: 6px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background-color: rgba(1, 148, 66, 1);
  border-radius: 9px;
}
body::-moz-scrollbar-thumb {
  background-color: rgba(1, 148, 66, 1);
  border-radius: 9px;
}

.hideReviews {
  display: none;
}
