.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* for header underline animation */
.header-underline-animation {
  display: inline-block;
  position: relative;
}
.header-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.header-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* for txt underline animation */
.txt-underline-animation {
  display: inline-block;
  position: relative;
}
.txt-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #004996;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.txt-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.webkitHighlight {
  -webkit-tap-highlight-color: transparent;
}

/* for custom scroll bar  */
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.custom-scroll::-moz-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
.custom-scroll:hover::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
  margin: 10px 20px;
}
.custom-scroll:hover::-moz-scrollbar-track {
  border-radius: 3px;
  background-color: transparent;
  margin: 10px 20px;
}
/* Handle */
.custom-scroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(77, 135, 103, 1);
  border-radius: 3px;
}
.custom-scroll:hover::-moz-scrollbar-thumb {
  background-color: rgba(77, 135, 103, 1);
  border-radius: 3px;
}

.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-10j9pna-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(158, 158, 158, 1),
    transparent
  );
}

.MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-wave.css-1ii6jb-MuiSkeleton-root {
  background-color: #a8a8a859;
}
MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-wave.css-13cv8j0 {
  background-color: #9e9e9e94;
}
.MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-wave.css-1ii6jb-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}
.MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-wave.css-13cv8j0::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}

.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-wave.css-hox0cd-MuiSkeleton-root {
  background-color: #a8a8a859;
}
.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-pulse.css-2w3lnn {
  background-color: #a8a8a859;
}

.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-wave.css-hox0cd-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}
.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-pulse.css-2w3lnn {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}

.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-wave.css-qbgezp {
  background-color: #a8a8a859;
}

.MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-wave.css-orvhwc {
  background-color: #a8a8a859;
}

.MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-wave.css-orvhwc::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}

.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-wave.css-18sfko2-MuiSkeleton-root {
  background-color: #a8a8a859;
}
.MuiSkeleton-root.MuiSkeleton-text.MuiSkeleton-wave.css-18sfko2-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}

.MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-wave.css-o5eitl-MuiSkeleton-root {
  background-color: #a8a8a859;
}
.MuiSkeleton-root.MuiSkeleton-rectangular.MuiSkeleton-wave.css-o5eitl-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}

.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-132jqa9-MuiSkeleton-root {
  background-color: #a8a8a859;
}
.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-132jqa9-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}

.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-1l07ga5-MuiSkeleton-root {
  background-color: #a8a8a859;
}
.MuiSkeleton-root.MuiSkeleton-rounded.MuiSkeleton-wave.css-1l07ga5-MuiSkeleton-root::after {
  background: linear-gradient(
    90deg,
    transparent,
    rgb(255, 255, 255),
    transparent
  );
}

.PolicyMarkdown h1 {
  font-size: 18px;
  font-weight: 900;
  padding-top: 10px;
  color: black;
  padding-bottom: 5px;
}

.PolicyMarkdown ul {
  color: black;
  padding-bottom: 10px;
  list-style-type: disc;
  list-style-position: inside;
  font-size: 14px;
}

.PolicyMarkdown ul li::marker {
  color: "primary";
}

.css-821wyw-MuiSvgIcon-root {
  font-size: x-large;
}

.css-vubbuv {
  font-size: x-large;
}
